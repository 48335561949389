"use strict";

import $ from "jquery";

class TextareaCounter
{
    constructor(textarea, counter) {
        this.textarea = $(textarea);
        this.counter  = $(counter).hide();

        // Quietly exit if this.textarea or this.counter is missing.
        if (this.textarea.length < 1 || this.counter.length < 1) {
            return;
        }

        // Set the maxlength.
        this.maxlength = this.textarea.prop("maxlength");

        // Quietly exit if maxlength is undefined.
        if (typeof maxlength !== "undefined") {
            return;
        }

        this.listen();
        this.refresh();
    }

    listen() {
        this.textarea.on("blur", () => {
            this.counter.hide();
        });

        this.textarea.on("focus", () => {
            this.counter.show();
        });

        this.textarea.on("keyup", () => {
            this.refresh();
        });
    }

    refresh() {
        this.counter.html(`${this.maxlength - this.textarea.val().length} characters remaining`);
    }
}

export default TextareaCounter;
