"use strict";

import $ from "jquery";
import queryString from "query-string";

class SelectRedirect
{
    constructor(options = {name: null, event: null}) {
        this.select = $(`[name=${options.name}]`);
        this.name   = options.name;
        this.event  = options.event;

        // Quietly exit if this.select is missing.
        if (this.select.length < 1) {
            return;
        }

        // Error if these any of these options are missing.
        if (!this.name || !this.event) {
            throw new Error("Class instantiated incorrectly.");
        }

        this.setup();
    }

    setup() {
        let params = queryString.parse(location.search);

        delete params[this.name];

        if ("page" in params) {
            delete params["page"];
        }

        this.listen(`?${queryString.stringify(params)}`);
    }

    listen(params = "?") {
        this.select.on(this.event, (event) => {
            window.location.href = event.params.data.id
                                 ? `${params}&${this.name}=${event.params.data.id}`
                                 : `${params}`;
        });
    }
}

export default SelectRedirect;
