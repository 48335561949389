"use strict";

import $ from "jquery";
import Quill from "quill";

class QuillTextEditor
{
    constructor(options, textarea, output) {
        this.editor   = null;
        this.output   = output;
        this.textarea = textarea;

        // Quietly exit if this.output or this.textarea is missing.
        if (this.output.length < 1 || this.textarea.length < 1) {
            return;
        }

        this.setup(options);
    }

    setup(options) {
        this.editor = new Quill(this.textarea[0], options);

        // Remove tabbing
        const kb = this.editor.getModule("keyboard");
        delete kb.bindings[9];

        // Existing content?
        if (this.output.val().length > 0) {
            this.editor.setContents(this.editor.clipboard.convert(this.output.val()));
        }

        this.listen();
    }

    listen() {
        this.editor.on("text-change", () => {
            this.output.val(this.editor.root.innerHTML);
        });
    }
}

const Link = Quill.import("formats/link");

class MyLink extends Link
{
    static create(value) {
        let node = super.create(value);
        value = this.sanitize(value);

        if (! value.startsWith("http")) {
            value = "http://" + value;
        }

        node.setAttribute('href', value);

        return node;
    }
}

Quill.register(MyLink);

export default QuillTextEditor;
