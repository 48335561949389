"use strict";

import _ from 'lodash';
import $ from 'jquery';

// reference the top level vars from within an object as this will
// speed up rendering for cool technical reasons (scope-chain search)
_.templateSettings.variable = "rc";

class AddTranslation
{
    constructor(options) {
        // precompile any _ templates
        this.JST = {};
        this.JST.tab = $(options.template.tab).html();
        this.JST.translation = $(options.template.translation).html();
        this.listen(options, this.JST.translation);
    }

    listen(options) {
        if ($(options.add).length) {
            const tab = this.JST.tab;
            const translation = this.JST.translation;

            $(options.add).on('click', function(event) {
                event.preventDefault();

                const data = {
                    language: $(this).text(),
                    slug: $(this).data('slug')
                    //index: $(options.container).find(options.template).length
                };

                $(options.tabs).after(_.template(tab)(data));
                $(options.container).append(_.template(translation)(data));
            });
        }

        if ($(options.remove).length) {
            $(options.remove).on('click', function(event) {
                event.preventDefault();
            });
        }
    }
}

export default AddTranslation;
