/** @format */

"use strict";

// Load any Laravel & Bootstrap JavaScript dependencies.
require("./framework");

// Load any 3rd party JavaScript dependencies.
import rome from "rome";
// import Dropzone from "Dropzone";
import Select2 from "Select2";

// Load any TMS JavaScript dependencies.
import AddTranslation from "./components/temp/AddTranslation";
import FilePreview from "./components/temp/FilePreview";
import QuillTextEditor from "./components/temp/QuillTextEditor";
import SelectRedirect from "./components/temp/SelectRedirect";
import TextareaCounter from "./components/temp/TextareaCounter";

// Start.
const init = ($r, options) => {
  // No root node, no comprende!
  if ($r.length !== 1) {
    throw new Error("You must pass init() a valid root node.");
  }

  $("body").on("click", ".dismiss", function (event) {
    event.preventDefault();
    $(this).parent().remove();
  });

  $r.find(".select2").select2({
    width: "100%",
  });

  $r.find(".select2-remote").select2({
    ajax: {
      delay: 320,
      url: "/tms/lookup/origins",
      data: function (params) {
        return { lookup: params.term };
      },
      processResults: function (json) {
        return {
          results: $.map(json.data, function (item) {
            return {
              text: item.name,
              id: item.id,
            };
          }),
        };
      },
      cache: false,
    },
    minimumInputLength: 3,
    width: "100%",
  });

  $r.find(".dt").each((i, el) => {
    rome($(el)[0], options.formats.date);
  });

  // BS Filepicker
  $r.on("change", ".custom-file-input", function () {
    const fn = $(this).val().split("\\").pop();
    $(this).next(".custom-file-label").addClass("selected").html(fn);
  });

  $.each(options.selectRedirect, (i, options) => {
    new SelectRedirect(options);
  });

  $r.find(".quill").each((j, textarea) => {
    new QuillTextEditor(
      options.quill.options,
      $(textarea),
      $(textarea).next(".quill-output")
    );
  });

  $r.find(".countable").each((i, container) => {
    new TextareaCounter(
      $(container).find("textarea"),
      $(container).find(".counter")
    );
  });

  // Expander
  $r.on("click", ".expander", function (event) {
    event.preventDefault();

    const $this = $(this);

    let text = $this.find(".expander__text").text();
    let icon = $this.find(".expander__icon").text();

    if (text === "Show offer details") {
      text = "Hide offer details";
      icon = "expand_less";
    } else {
      text = "Show offer details";
      icon = "expand_more";
    }

    // Sets the link text and the material icon.
    $this.find(".expander__text").text(text);
    $this.find(".expander__icon").text(icon);
  });

  // Submit the notes form.
  $r.on("click", ".btn-notes", function (event) {
    const $this = $(this);
    if ($this.data("target") && $($this.data("target")).length) {
      $($this.data("target")).submit();
    }

    return false;
  });

  // Button activity indicator.
  $r.on("click", ".btn-item-remove", function (event) {
    event.preventDefault();

    const $this = $(this);
    const $div = $(`[data-remove-resource='${this.href}']`);
    const $input = $(`[data-remove-text='${this.href}']`);
    const icon = $this.find(".material-icons").text();

    if (!confirm($this.data("remove-message"))) {
      return;
    }

    $this.find(".material-icons").text("refresh");
    $this.prop("disabled", true).addClass("rotate");

    axios
      .get(this.href)
      .then(function (response) {
        $div.remove();
        $input.val("");
        $this.prop("disabled", false).removeClass("rotate");
        $this.find(".material-icons").text(icon);
      })
      .catch(function (error) {
        $this.prop("disabled", false).removeClass("rotate");
        $this.find(".material-icons").text(icon);
      });
  });

  $r.on("submit", "form", function (event) {
    // Are we using remote validation
    if (!$(event.target).data("remote-validation")) {
      return true;
    }

    const $form = $(event.target);
    const action = $form.prop("action");

    $form.find(".btn").prop("disabled", true);

    axios
      .patch(`${action}/validator`, $form.serialize())
      .then(function (response) {
        if (response.status === 200 && response.data) {
          event.target.submit();
        } else {
          // Something else happened, just enable the button.
          $form.find(".btn").prop("disabled", false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          $r.find("#tpl-remote").empty();
          $r.find(".is-invalid").removeClass("is-invalid");
          $r.find(".remote-error-display").remove();

          $("html, body").animate({ scrollTop: 0 }, 240);

          let tplData = "";
          $.each(error.response.data.errors, (name, error) => {
            let $input = null;
            let $error = null;

            const errorMessage = `<p class="remote-error-display small text-danger d-flex align-items-center mt-2 mb-0"><i class="material-icons mr-1">error</i><strong>${error[0]}</strong></p>`;

            const sharedErrorMessage = `<p class="remote-error-display small text-danger d-flex align-items-center mt-0 mb-0"><i class="material-icons mr-1">error</i><strong>${error[0]}</strong></p>`;

            // handles standard name type
            if ($(`[name="${name}"]`).length) {
              $input = $(`[name="${name}"]`).addClass("is-invalid");

              if ($(`.input-error-${name}`).length) {
                $error = $(`.input-error-${name}`);
                $error.append(errorMessage);
              }
            }
            // handles deep nested name types
            else if ($(`.${name.replace(/\./g, "-")}`).length) {
              $input = $(`.${name.replace(/\./g, "-")}`).addClass("is-invalid");

              if ($(`.input-error-${name.replace(/\./g, "-")}`).length) {
                $error = $(`.input-error-${name.replace(/\./g, "-")}`);
                $error.append(errorMessage);
              }
            }
            // do we have an error ID?
            else {
              if (name.split(".")[1].length && name.split(".")[2].length) {
                $error = $(
                  `[data-error-id="${name.split(".")[1]}-${
                    name.split(".")[2]
                  }"]`
                );
                $error.append(sharedErrorMessage);
              }
            }

            // Generic field errors
            $input = $(`input.${name.split(".")[1]}`);
            if ($input.length) {
              if ($input.hasClass("is-invalid")) {
                $input.removeClass("is-invalid");
              } else {
                $input.addClass("is-invalid");
              }
            }

            tplData += `<dd class="d-flex align-items-center mt-2">
                            <i class="material-icons md-36 mr-2">error_outline</i>
                            <p class="error m-0"><strong>${error[0]}</strong></p>
                        </dd>`;
          });

          const tpl = `<div class="card-body bg-danger text-white">
                            <dl class="mb-0">
                                ${tplData}
                            </dl>
                        </div>`;

          $r.find("#tpl-remote").append(tpl);
        }

        // In all instances enable the button.
        $form.find(".btn").prop("disabled", false);
      });

    return false;
  });

  // Set Status
  $r.on("click", ".btn-status", function (event) {
    $r.find("[name=status]").val($(this).data("status"));
  });

  // Offers, Videos and Gallery
  _.templateSettings.variable = "rc";

  const JST = {
    offer: $("script.tpl-offer").html(),
    image: $("script.tpl-image").html(),
    video: $("script.tpl-video").html(),
  };

  // Videos, Images, Offers
  $.each(["video", "image", "offer"], function (index, type) {
    $r.on("click", `#tpl-${type}-clone`, function (event) {
      event.preventDefault();

      const domid = Math.random().toString(36).replace(".", "");
      const fragment = _.template(JST[type].replace(/PLACEHOLDER/g, domid));

      $(`#tpl-${type}-container`).append(fragment);

      // Offer specific
      if (type === "offer") {
        $(`#${type}-${domid}`).find(".select2").select2();
        $(`#${type}-${domid}`)
          .find(".dt")
          .each((i, el) => {
            rome($(el)[0], options.formats.date);
          });
        $(`#${type}-${domid}`)
          .find(".quill")
          .each((j, textarea) => {
            new QuillTextEditor(
              options.quill.options,
              $(textarea),
              $(textarea).next(".quill-output")
            );
          });
      }

      // Remove
      $(`#${type}-${domid}`).on(
        "click",
        `.tpl-${type}-remove`,
        function (event) {
          event.preventDefault();
          $(`#${type}-${domid}`).remove();
        }
      );
    });
  });

  // Set full season
  $("#season_start").on("change", function () {
    if ($("input[name='all_year_operation']").is(":checked")) {
      $("input[name='all_year_operation']").prop("checked", false);
    }
  });

  $r.on("change", ".set-season", function (event) {
    if ($(this).is(":checked")) {
      $("#season_start")
        .find('option[value="January"]')
        .prop("selected", true)
        .change();

      $("#season_end")
        .find('option[value="December"]')
        .prop("selected", true)
        .change();

      $("input[name='all_year_operation']").prop("checked", true);
    } else {
      $("#season_start").find("option").prop("selected", false).change();

      $("#season_end").find("option").prop("selected", false).change();
    }
  });

  $r.on("change", "input[name='has_days_out_50pc']", function (event) {
    if ($(this).is(":checked")) {
      $("#group--days_out_50pc").removeClass("d-none");
    } else {
      $("#group--days_out_50pc").addClass("d-none");
    }
  });
}; // end

/**
 * Init the "app" by passing in the root node and any options we need.
 */
init($("#tours-content-container"), {
  formats: {
    date: {
      inputFormat: "DD MMM YYYY hh:mm A",
      monthsInCalendar: 1,
    },
  },

  quill: {
    options: {
      debug: false,
      modules: {
        toolbar: [["bold", { list: "bullet" }, "link"]],
      },
      placeholder: "",
      theme: "snow",
    },
  },

  selectRedirect: [
    {
      name: "status",
      event: "select2:select",
    },
    {
      name: "operator",
      event: "select2:select",
    },
  ],
});

jQuery.fn.exists = function () {
  return this.length > 0;
};
